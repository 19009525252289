import React from 'react';
import { graphql } from 'gatsby';
import { Parallax } from 'react-spring/renderprops-addons';

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import ComponentParser from '../components/component.parser';
import Content from '../components/component.content';
import CookieNotice from "../components/component.cookie-notice";

const PageTemplate = ({ data }) => {
    const { title, content, blocks, seo, featuredImage, language, translations, slug } = data.wordpress.page;
    
    let pageLanguage = 'EN';
    if(typeof language !== "undefined") {
        pageLanguage = language.code;
    }

    return (
        <>
            <Parallax scrolling={true}>
                <Layout language={language} translations={translations}>
                    <SEO title={title} seo={seo} image={featuredImage} translations={translations} slug={slug} language={language} />
                    {blocks &&
                        <ComponentParser blocks={blocks} parallax language={pageLanguage} />
                    }
                    {content &&
                        <Content content={content} />
                    }
                </Layout>
            </Parallax>
            <CookieNotice />
        </>
    );
};

export const query = graphql`
    query($id: ID!) {
        wordpress {
            page(id: $id) {
                title
                slug
                content
                language {
                    code
                    locale
                }
                translations {
                    # id
                    uri
                    language {
                        code
                        locale
                    }
                }
                featuredImage {
                    node {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1140) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
                seo {
                    ...SeoPostFields
                }
                ...PageBlocks
            }
        }
    }
`;

export default PageTemplate;
